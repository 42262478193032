import React, {Component} from 'react';
import PrivatePage from "../common/containers/PrivatePage";
import Content from "../common/containers/Content";
import Col from "../common/containers/Col";
import Row from "../common/containers/Row";
import AdvisoryLeadForm from "./AdvisoryLeadForm";
import URLS from "../urls";

class AdvisoryLeadPage extends Component {
    render() {
        return (
            <PrivatePage title="Solicitar assessoria acadêmica">
                <Content>
                    <Row>
                        <Col mdOffset={2} md={8}>
                            <AdvisoryLeadForm
                                successElement={
                                    <h4>
                                        Clique <a href={URLS.advisoryProducts}>aqui</a> se quiser saber mais sobre nossa assessoria acadêmica.
                                    </h4>
                                }
                            />
                        </Col>
                    </Row>
                </Content>
            </PrivatePage>
        );
    }
}

export default AdvisoryLeadPage;